@import './settings/variable';
@import './settings/mixin.scss';
@import './settings/global.scss';
@import './btn';
@import './form';

@import '~font-awesome/css/font-awesome.min.css';
@import './settings/variable';

//Components
@import './components/form.scss';
@import './components/table';
@import './components/date-picker.scss';

//Containers
@import './containers/view-invoices-page.scss';
@import './containers/make-payment-page.scss';
@import './containers/view-transactions-page.scss';
@import './containers/global-page.scss';
@import './containers/customer_page.scss';
@import './base/scaffolding.scss';
@import './base/typography.scss';

@mixin clearfix() {
  .temp,
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

.manage-page {
  .manage-page__form {
    width: 100%;
    min-height: 83vh;

    position: relative;
    padding-top: 40px !important;
    padding-bottom: 90px !important;
    justify-content: center;
    margin-top: 120px;
    // overflow: scroll;
    display: flex;
    .form-wrapper {
      width: 98%;
      background-color: #e8e7e7;
      border-radius: 0px;
      padding: 5px 10px;
    }
  }
}

.account-detail {
  margin-left: 10px;
}

.global-page .table-title-form-detail {
  background-color: white;
}

.card-headerb {
  background-color: rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
  margin-top: 0px;
}

.account-assets,
.account-services,
.account-balance,
.account-transaction {
  border: 0px solid #dee5e7;
  background-color: #fff;
  padding: 0px;
}

.card-headerb .mb-0 {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.875rem;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  margin: auto;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  margin-left: 0px;
}
.card-title-mb {
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 0px;
}

.btn-active {
  color: #354052 !important;
}

.title-form {
  background-color: $color_border_dark;
  padding: 15px;
}

.title-form-detail {
  background-color: white;
  border: 1px solid $color_border_dark;
}

.table-hover {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.title-account-number {
  padding: 8px 30px !important;
  margin: auto 0px auto auto;
  background-color: #ffffff;
  color: #555555a0;
  font-size: 14px;
}

.account-num-header {
  padding-left: 25px;
}

.account-number {
  margin-right: 20px;
  float: right;
}

.no-padding {
  padding: 0px;
}

.rccs__card--front {
  // background: linear-gradient(25deg, #0e0c0c, rgb(136, 133, 133)) !important;
  background: linear-gradient(25deg, #0e0e0d, #806d13de) !important;

  .rccs__name {
    padding-right: 20px;
  }
}

.rccs__card--back {
  background: linear-gradient(25deg, #0e0e0d, #806d13de) !important;
}

.no-border-focus:focus {
  outline: none !important;
}

.form-group {
  label {
    margin-bottom: 3px;
  }
}

.color-default {
  color: #1e7e34 !important;
}

.no-border {
  border: none;
  outline: none;
}

.header-table {
  font-weight: bold;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1e7e34;
  background-color: #1e7e34;
}

.btn-submit {
  background: #28a745;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  border-radius: 3px;
  font-size: 0.875rem;
  padding: 0.29rem 0.75rem;
  // text-align: center;
  border: 2px solid #28a745 !important;
}

.btn-submit:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-cancel {
  color: #323232 !important;
  background: transparent !important;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  border-radius: 3px;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  letter-spacing: 1px;
  border: 1px solid #323232;
}

.btn-cancel:hover {
  color: #ffffff !important;
  background: #323232 !important;

  border-color: #323232;
}

.btn-add {
  color: #626262 !important;
  background: transparent !important;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  border-radius: 3px;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  letter-spacing: 1px;
  border: 1px solid #eeeeee;
}

.btn-add:hover {
  background: #28a745 !important;
  color: #ffffff !important;
  border-color: #28a745 !important;
}

.btn-login {
  color: #fff !important;
  background: #333333 !important;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  border-radius: 3px;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  letter-spacing: 1px;
  border: 1px solid #eeeeee;
}

.btn-login:hover {
  background: #00a651 !important;
  color: #ffffff !important;
  border-color: #00a651 !important;
}

button:focus {
  outline: none !important;
}

// collapse start //

.form-collapse {
  margin-bottom: 0px !important;
  .card-body {
    padding: 0px;
  }

  > .col-lg-12 {
    padding: 0;
  }
  .collapse__wrapper {
    &.boxed {
      border: none;
      .collapse__title {
        background-color: #f6f7f8;
        color: #626262;
        padding: 0;
        border-radius: 3px;
      }
      .collapse__title:focus {
        outline: none;
      }

      &.collapse__content {
        margin: 15px;
      }
      .form-container {
        padding: 0px !important;
      }
      .panel__heading {
        &.panel__heading--btn {
          .panel__title {
            font-size: 1.143rem;
            font-weight: 500;
            display: inline-block;
            padding: 8px 8px;
          }
        }
      }
    }
    &.active {
      .collapse__title {
        background-color: #28a745 !important;
        color: #ffffff;
      }

      .panel__heading {
        &.panel__heading--btn {
          .i-collapsible {
            background-color: #ffffff;
            color: #28a745;
          }
        }
      }
    }
    .panel__heading {
      &.panel__heading--btn {
        .i-collapsible {
          background-color: #323232;
          float: right;
          border-radius: 100%;
          padding: 5px 6px;
          margin-top: 10px;
          margin-right: 20px;
          color: #ffffff;
        }
      }
    }
    .collapse__title:hover {
      cursor: pointer;
      background-color: #28a745;
      color: #ffffff;

      .panel__heading {
        &.panel__heading--btn {
          .i-collapsible {
            background-color: #ffffff !important;
            color: #28a745 !important;
          }
        }
      }
    }
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

// css sub collapse

.form-collapse {
  margin-bottom: 0px !important;

  > .col-lg-12 {
    padding: 0;
  }
  .collapse__wrapper {
    &.boxed {
      border: none;
      .collapse_sub_title {
        background-color: #f6f7f8;
        color: #626262;
        padding: 0;
        border-radius: 3px;
      }
      .collapse_sub_title:focus {
        outline: none;
      }

      &.collapse__content {
        margin: 15px;
      }
      .form-container {
        padding: 0px !important;
      }
      .panel_sub_heading {
        &.panel_sub_heading--btn {
          .panel__title {
            font-size: 1.143rem;
            font-weight: 500;
            display: inline-block;
            padding: 8px 8px;
          }
        }
      }
    }
    &.active-sub-from {
      .collapse_sub_title {
        background-color: #28a745;
        color: #ffffff;
      }

      .panel_sub_heading {
        &.panel_sub_heading--btn {
          i {
            background-color: #ffffff;
            color: #28a745;
          }
        }
      }
    }
    .panel_sub_heading {
      &.panel_sub_heading--btn {
        .i-collapsible {
          background-color: #323232;
          float: right;
          border-radius: 100%;
          padding: 5px 6px;
          margin-top: 10px;
          margin-right: 20px;
          color: #ffffff;
        }
      }
    }
    .collapse_sub_title:hover {
      cursor: pointer;
      background-color: #28a745;
      color: #ffffff;

      .panel_sub_heading {
        &.panel_sub_heading--btn {
          .i-collapsible {
            background-color: #ffffff;
            color: #28a745;
          }
        }
      }
    }
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

///
.form-section {
  @include clearfix();
  ul {
    padding-left: 0;
    li {
      list-style-type: none;
    }
  }
  section {
    border: 1px solid $color_border_dark;
    margin: 10px;
    .table-title {
      position: relative;
      .form-section__icon-trash {
        position: absolute;
        top: 15px;
        right: 10px;
        cursor: pointer;
        i {
          font-size: 18px;
        }
        &.form-section__icon-trash--show {
          display: block;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .form-content {
      padding: 20px;
      .form-content__center {
        width: 70%;
        margin: 0 auto;
      }
      .form__block {
        padding: 20px;
        border: 1px solid $color_border_dark;
        position: relative;
        margin-bottom: 10px;
        .form__half:first-child {
          margin-right: 30px;
        }
        button {
          position: absolute;
          top: 10px;
          right: 5px;
        }
      }
    }
    .form-content.body-modal-add-component,
    .form-content.body-modal-submit {
      border: 1px solid #b6d6b6;
      background-color: #e6f2e6;
    }
    .form-content.body-modal-cancel {
      border: 1px solid #d9b4b4;
      background-color: #f2e6e6;
    }
  }
  .form-section__btn-add {
    margin-left: 65px;
    &.form-section__btn-add-input {
      margin-left: 157px;
    }
  }
  .form-section__btn-next {
    float: right;
  }
}
.section-form-child {
  margin: 20px 0 0 0;
  .table-title {
    position: relative;
    padding: 15px;
    margin-bottom: 20px;
    .form-section__icon-trash {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
  }
  .form-content {
    padding: 0 20px 20px 20px;
  }
  &:first-child {
    margin: 0;
  }
}
.form-section {
  .section {
    position: relative;
    margin: 0;
    .button-trash {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 0 5px;
      i {
        color: #ff4861;
        font-size: 20px;
      }
      cursor: pointer;
      &:hover {
        background-color: darken(#ff4861, 10%);
        border-color: darken(#ff4861, 10%);
        i {
          color: darken(#fff, 20%);
        }
      }
    }
    .form-content {
      &.form-content--trash {
        margin-top: 20px;
      }
    }
    &.section-form-order {
      border: none;
      margin: 0;
      .button-trash {
        top: -43px;
        right: 0;
      }
    }
  }
}
// collapse end //

.label-header {
  white-space: nowrap;
}

.disabled {
  background-color: #ffffff !important;
  border: 1px solid !important;
  border-color: hsl(0, 0%, 80%) !important;
  border-top-color: rgb(204, 204, 204) !important;
  border-right-color: rgb(204, 204, 204) !important;
  border-bottom-color: rgb(204, 204, 204) !important;
  border-left-color: rgb(204, 204, 204) !important;
}

.form-control[readonly] {
  background-color: #ffffff !important;
  border: 1px solid !important;
  border-radius: 4px !important;
  border-color: hsl(0, 0%, 80%) !important;
  border-top-color: rgb(204, 204, 204) !important;
  border-right-color: rgb(204, 204, 204) !important;
  border-bottom-color: rgb(204, 204, 204) !important;
  border-left-color: rgb(204, 204, 204) !important;
}

.custom-react-select__control--is-disabled {
  background-color: #ffffff !important;
  border: 1px solid !important;
  border-radius: 4px !important;
  border-color: hsl(0, 0%, 80%) !important;
  border-top-color: rgb(204, 204, 204) !important;
  border-right-color: rgb(204, 204, 204) !important;
  border-bottom-color: rgb(204, 204, 204) !important;
  border-left-color: rgb(204, 204, 204) !important;
}

.btn-link {
  color: #39b54a !important;
}

.read-only {
  .css-ue83k8-singleValue {
    color: black;
  }
}

.css-1hwfws3 {
  padding: 0px !important;

  .css-151xaom-placeholder {
    padding: 0px !important;
    margin-left: 5px;
  }

  .css-dvua67-singleValue {
    margin-left: 5px;
  }

  .css-1g6gooi {
    padding: 0px !important;
    margin: 0px;
    margin-left: 5px;
  }
}

.fa-ico {
  position: absolute;
  right: 25px;
  top: 40px;
  color: #c2c2c2;
}
.form-wrapper {
  background-color: transparent !important;
}

table th.sortable {
  position: relative;
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    border-width: 0 5px 5px;
    border-style: solid;
    border-color: #000 transparent;
    visibility: visible;
    right: 8px;
    top: 50%;
    position: absolute;
    opacity: 0.4;
    margin-top: -5px;
  }
  &::before {
    margin-top: 2px;
    border-bottom: none;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
  }
  &.sorting {
    &::before,
    &::after {
      color: #28a745;
    }
    &::before {
      visibility: hidden;
    }
  }
  &.sorting-asc {
    &::after {
      border-top: none;
      border-bottom: 5px solid #28a745;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      visibility: visible;
      opacity: 1;
      margin-top: -2px;
    }
  }
  &.sorting-desc {
    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      visibility: visible;
      opacity: 1;
      border-bottom: none;
      border-top: 5px solid #28a745;
      box-shadow: none;
      margin-top: -2px;
    }
  }

  .label-header {
    margin-right: 15px;
  }
}

.btn-toggle-type {
  float: right;
  margin-left: -55px;
  margin-top: 0px;
  padding-right: 5px;
  background-color: transparent;
  border: none;
  position: relative;
  z-index: 2;

  .icon-show-password {
    font-size: 20px;
    color: #70706d;
  }
}

.phone-group {
  display: flex;
}

.form-group,
.form__form-group {
  .is-required {
    color: #dc3545;
    position: absolute;
    font-size: 5px;
    margin: 4px 3px;
  }
}

.read-only {
  .custom-react-select__single-value--is-disabled {
    color: #212529;
  }
}
.sticky-header {
  max-height: 500px !important;
  overflow: auto;
  table {
    width: 100%;
    // text-align: center;
    border-collapse: collapse;
  }
  // table tr th,
  // table tr td {
  //   border: 2px solid;
  // }
  table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    background-color: #fefefe;
    z-index: 1;
  }

  /* here is the trick */
  table tbody:nth-of-type(1) tr:nth-of-type(1) td {
    border-top: none !important;
  }
  table thead th {
    // border-bottom: 2px solid #dee2e6;
    // border-bottom: none !important;
    // z-index: 2;
    box-shadow: inset 0 1px 0 #dee2e6, inset 0 -1px 0 #dee2e6;
    // padding: 2px 0;
  }
  table thead th {
    // background-clip: padding-box;
  }
}

.switch-non-active {
  .label-switch::before {
    content: 'N' !important;
    width: 23px !important;
    height: 23px !important;
    text-align: center;
    color: #dc3545;
    top: -2px !important;
    line-height: 22px;
    margin-left: -2px !important;
  }
}

.switch-active {
  .label-switch::before {
    content: 'Y' !important;
    width: 23px !important;
    height: 23px !important;
    text-align: center;
    color: #28a745;

    top: -2px !important;
    line-height: 22px;
    margin-left: -2px !important;
  }
}

.checkbox.checbox-switch {
  padding-left: 0;
}

.checkbox.checbox-switch label,
.checkbox-inline.checbox-switch {
  display: inline-block;
  position: relative;
  padding-left: 0;
}

.checkbox.checbox-switch label input,
.checkbox-inline.checbox-switch input {
  display: none;
}

.checkbox.checbox-switch label span,
.checkbox-inline.checbox-switch span {
  width: 55px;
  border-radius: 20px;
  height: 28px;
  border: 1px solid #eeeeee;
  background-color: #b5b5b5;
  border-color: #eeeeee;
  box-shadow: #dfdfdf 0px 0px 0px 0px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.checkbox.checbox-switch label span:before,
.checkbox-inline.checbox-switch span:before {
  display: inline-block;
  width: 26px !important;
  height: 26px !important;
  border-radius: 50% !important;
  background: #ffffff;
  content: ' ';
  top: 0;
  position: relative;
  left: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
}

.checkbox.checbox-switch label > input:checked + span:before,
.checkbox-inline.checbox-switch > input:checked + span:before {
  left: 26px;
}

/* Switch Default */
.checkbox.checbox-switch label > input:checked + span,
.checkbox-inline.checbox-switch > input:checked + span {
  background-color: #b4b6b7;
  border-color: #b4b6b7;
  // box-shadow: #b4b6b7 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

.checkbox.checbox-switch label > input:checked:disabled + span,
.checkbox-inline.checbox-switch > input:checked:disabled + span {
  background-color: gainsboro;
  border-color: gainsboro;
  box-shadow: gainsboro 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

.checkbox.checbox-switch label > input:disabled + span,
.checkbox-inline.checbox-switch > input:disabled + span {
  background-color: #e8ebee;
  border-color: #ffffff;
}

.checkbox.checbox-switch label > input:disabled + span:before,
.checkbox-inline.checbox-switch > input:disabled + span:before {
  background-color: #343a40;
  border-color: #f3f3f3;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

/* Switch Light */
.checkbox.checbox-switch.switch-light label > input:checked + span,
.checkbox-inline.checbox-switch.switch-light > input:checked + span {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: #f8f9fa 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

/* Switch Dark */
.checkbox.checbox-switch.switch-dark label > input:checked + span,
.checkbox-inline.checbox-switch.switch-dark > input:checked + span {
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: #343a40 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

.checkbox.checbox-switch.switch-dark label > input:checked:disabled + span,
.checkbox-inline.checbox-switch.switch-dark > input:checked:disabled + span {
  background-color: #646668;
  border-color: #646668;
  box-shadow: #646668 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

/* Switch Success */
.checkbox.checbox-switch.switch-success label > input:checked + span,
.checkbox-inline.checbox-switch.switch-success > input:checked + span {
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: #28a745 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

.checkbox.checbox-switch.switch-success label > input:checked:disabled + span,
.checkbox-inline.checbox-switch.switch-success > input:checked:disabled + span {
  background-color: #99d9a8;
  border-color: #99d9a8;
  box-shadow: #99d9a8 0px 0px 0px 8px inset;
}

/* Switch Danger */
.checkbox.checbox-switch.switch-danger label > input:checked + span,
.checkbox-inline.checbox-switch.switch-danger > input:checked + span {
  background-color: #c82333;
  border-color: #c82333;
  box-shadow: #c82333 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

.checkbox.checbox-switch.switch-danger label > input:checked:disabled + span,
.checkbox-inline.checbox-switch.switch-danger > input:checked:disabled + span {
  background-color: #d87781;
  border-color: #d87781;
  box-shadow: #d87781 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

/* Switch Primary */
.checkbox.checbox-switch.switch-primary label > input:checked + span,
.checkbox-inline.checbox-switch.switch-primary > input:checked + span {
  background-color: #0069d9;
  border-color: #0069d9;
  box-shadow: #0069d9 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

.checkbox.checbox-switch.switch-primary label > input:checked:disabled + span,
.checkbox-inline.checbox-switch.switch-primary > input:checked:disabled + span {
  background-color: #6da3dd;
  border-color: #6da3dd;
  box-shadow: #6da3dd 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

/* Switch Info */
.checkbox.checbox-switch.switch-info label > input:checked + span,
.checkbox-inline.checbox-switch.switch-info > input:checked + span {
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: #17a2b8 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

.checkbox.checbox-switch.switch-info label > input:checked:disabled + span,
.checkbox-inline.checbox-switch.switch-info > input:checked:disabled + span {
  background-color: #66c0ce;
  border-color: #66c0ce;
  box-shadow: #66c0ce 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

/* Switch Warning */
.checkbox.checbox-switch.switch-warning label > input:checked + span,
.checkbox-inline.checbox-switch.switch-warning > input:checked + span {
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: #ffc107 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

.checkbox.checbox-switch.switch-warning label > input:checked:disabled + span,
.checkbox-inline.checbox-switch.switch-warning > input:checked:disabled + span {
  background-color: #e2c366;
  border-color: #e2c366;
  box-shadow: #e2c366 0px 0px 0px 8px inset;
  transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

// Handle Switch export

.switch-export-non-active {
  .label-switch::before {
    content: 'E' !important;
    width: 23px !important;
    height: 23px !important;
    text-align: center;
    color: #dc3545 !important;
    top: -2px !important;
    line-height: 22px;
    margin-left: -2px !important;
  }
}

.switch-export-active {
  .label-switch {
    background-color: #28a745 !important;
    box-shadow: #28a745 0px 0px 0px 8px inse !important;
  }
  .label-switch::before {
    content: 'P' !important;
    width: 23px !important;
    height: 23px !important;
    text-align: center;
    color: #28a745 !important;

    top: -2px !important;
    line-height: 22px;
    margin-left: -2px !important;
  }
}

.email-group {
  display: flex;
  margin-right: 20px;

  .email-form {
    width: auto;
    height: 30px;
    display: flex;
    .wr-input {
      min-width: 200px !important;
    }

    .label-item {
      margin-top: 10px;
    }
    .input-group {
      width: 90% !important;
    }
  }

  .react-tagsinput-input {
    margin: 0px;
    margin-top: -10px;
    padding: 0px;
    min-width: 200px !important;
  }
}

.display-none {
  display: none;
}

.btn-default-height {
  height: 35px;
}

.top-header {
  z-index: 9 !important;
}

.nav-menu {
  z-index: 8 !important;
}

.email-input {
  height: auto !important;
}
.react-tagsinput-tag {
  background-color: #28a745 !important;
  color: white !important;
}

.label-switch {
  margin-left: 10px;
  cursor: pointer;
}

.no-background {
  background: none;
}
