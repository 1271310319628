.manage-credit-card-page {
  .manage-credit-card-page__form {
    width: 100%;
    min-height: 85vh;
    // height: auto;
    position: relative;
    padding-top: 40px !important;
    padding-bottom: 50px;
    justify-content: center;
    margin-top: 120px;
    // overflow: scroll;
    display: flex;
    .form-wrapper {
      width: 96%;
      background-color: #fff;
      border-radius: 0px;
      padding: 20px 20px;
      height: 330px;
    }
  }

  .group-card-token {
    display: flex;

    .btn-card {
      margin-left: 20px;
      padding-right: 25px;
    }
  }
}

.btnback {
  text-align: center;
  vertical-align: middle;
  user-select: none;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 194, 146);
  border-color: rgb(0, 194, 146);
  font-size: 0.875rem;
  font-family: Poppins, sans-serif;
  line-height: 1.5;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  box-shadow: 0 0 0 0.2rem rgba(38, 203, 162, 0.5);
}

.btnback:hover {
  color: #fff;
  background-color: #009c75;
  border-color: #008f6c;
}

.button-delete-card {
  margin-left: -30px;
  padding-right: 10px;
  z-index: 2;
  // color: #1e7e34;
  border: none;
  background: transparent;
  font-size: 19px;
}

.button-delete-card:focus {
  outline: 0;
}

.button-delete-card:disabled {
  margin-left: -30px;
  padding-right: 10px;
  z-index: 2;
  pointer-events: none;
  color: #e2d4d4;
  font-size: 19px;
}
